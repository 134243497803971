<template>
  <div
    class="card"
    style="border-radius: 0.5px; min-height: 100%; max-width: 100%"
  >
    <div class="row">
      <!-- icon to show/hide folders -->

      <div :class="columnFolders()" :style="foldersWidth()">
        <span @click="showFolders = !showFolders" class="fs-5 text-end">
          <span
            v-if="showFolders"
            class="fs-1 m-2 svg-icon svg-icon-1 svg-icon"
          >
            <inline-svg src="media/icons/duotune/arrows/arr074.svg" />
          </span>
          <span v-else class="fs-1 m-2 svg-icon svg-icon-1 svg-icon">
            <inline-svg src="media/icons/duotune/arrows/arr071.svg" />
          </span>
        </span>
        <ul v-show="showFolders">
          <li v-for="(documents, folderName) in folders" :key="folderName">
            <span
              @click="toggleExpanded(folderName)"
              class="fs-5"
              :style="selectedStyles(folderName)"
            >
              <span class="fs-1 m-2 svg-icon svg-icon-1 svg-icon">
                <inline-svg src="media/icons/duotune/files/fil012.svg" />
              </span>
              {{ folderName }}
              <!--          <i v-if="isExpanded(folderName)" class="fas fa-caret-down"></i>-->
              <!--          <i v-else class="fas fa-caret-right"></i>-->
            </span>
          </li>
        </ul>
      </div>
      <div :class="columnsTableDocuments()">
        <CustomTableDocuments
          :documents="expandedFolder"
          :loading="loading"
          @downloadPdf="loadB64"
        />
      </div>
    </div>
  </div>

  <!--begin::Modal - Share & Earn-->
  <div
    class="modal"
    ref="kt_modal_display"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered">
      <!--begin::Modal content-->
      <div class="modal-content" id="displayer">
        <div class="modal-close d-flex justify-content-end">
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Share & Earn-->

</template>

<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import ApiService from "@/core/services/ApiService";
import CustomTableDocuments from "@/components/customTables/CustomTableDocuments.vue";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "ClientProductsManuals",
  components: {
    CustomTableDocuments,
  },
  data() {
    return {
      folders: [],
      expandedFolder: [],
      expandedFolderName: "",
      showFolders: true,
      loading: false,
    };
  },
  computed: {},
  methods: {
    toggleExpanded(folderName) {

      //if(this.loading) this.loading = false;

      this.expandedFolderName = folderName;
      if (folderName) {
        this.expandedFolder = this.folders[folderName];
      } else {
        this.expandedFolder = [];
      }
    },
    isExpanded(folderName) {
      return this.expandedFolder === this.folders[folderName];
    },
    loadTechManuals() {
      let clientID = store.getters.currentUser.numcl;
      ApiService.get(`artigos/manuais`)
        .then((response) => {
          this.folders = response.data.Data.documents;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadB64(documentName, isDownload = false) {

      if(this.loading) return;

      let documentFolder = this.expandedFolderName;

      this.loading = true;

      ApiService.get(
        `artigos/manuais/base64?folder=${documentFolder}&filename=${documentName}`
      )
        .then((response) => {

          let file = response.data.Data.docBase64;
          let fileName = response.data.Data.docName;
          const parts = fileName.split(".");
          let fileExtension = parts[parts.length - 1];
          let mimeType = "application/pdf";
          if (fileExtension === "xml") {
            mimeType = "application/xml";
          }
          //if txt
          else if (fileExtension === "txt") {
            mimeType = "text/plain";
          }
          //if jpg
          else if (fileExtension === "jpg") {
            mimeType = "image/jpeg";
          }
          //if png
          else if (fileExtension === "png") {
            mimeType = "image/png";
          }
          //if gif
          else if (fileExtension === "gif") {
            mimeType = "image/gif";
          }
          //if doc
          else if (fileExtension === "doc") {
            mimeType = "application/msword";
          }
          //if docx
          else if (fileExtension === "docx") {
            mimeType =
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          }
          //if xls
          else if (fileExtension === "xls") {
            mimeType = "application/vnd.ms-excel";
          }
          //if xlsx
          else if (fileExtension === "xlsx") {
            mimeType =
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          }
          //if ppt
          else if (fileExtension === "ppt") {
            mimeType = "application/vnd.ms-powerpoint";
          }
          //if pptx
          else if (fileExtension === "pptx") {
            mimeType =
              "application/vnd.openxmlformats-officedocument.presentationml.presentation";
          }
          //if zip
          else if (fileExtension === "zip") {
            mimeType = "application/zip";
          }
          //if rar
          else if (fileExtension === "rar") {
            mimeType = "application/x-rar-compressed";
          }
          //if mp3
          else if (fileExtension === "mp3") {
            mimeType = "audio/mpeg";
          }

          if(isDownload) {
            this.download(mimeType, file, fileName);
            return;
          }

          switch (fileExtension) {
            case "url":
              // Get the actual extension
              fileExtension = parts[parts.length - 2];
              this.displaySharedFile(mimeType, file, fileName);
              return;
            case "txt":
            case "pdf":
            case "doc":
            case "docx":
            case "xls":
            case "xlsx":
            case "ppt":
            case "pptx":
            case "xml":
              this.displayFile(mimeType, file, fileName);
              return;
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
              this.displayImage(mimeType, file, fileName);
              return;
            case "mp4":
            case "avi":
            case "mov":
              this.displayVideo(mimeType, file, fileName);
              return;
            case "mp3":
            case "wav":
              this.displayAudio(mimeType, file, fileName);
              return;
            default:
              this.download(mimeType, file, fileName);
          }

          /* 
            <iframe
              class="embed-responsive-item rounded h-300px w-100"
              src="https://www.youtube.com/embed/qIHXpnASPAA"
              allowfullscreen=""
            ></iframe>
            */
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    displaySharedFile(mimeType, file, fileName) {
      console.log("mimeType: ".mimeType)
      let decodedData = atob(file);
      const lines = decodedData.split("\r\n");
      const urlLine = lines.find((line) => line.startsWith("URL="));
      const url = urlLine.slice("URL=".length);
      this.loading = false;
      let newTab = window.open(url, "_blank");
      if (newTab) {
        newTab.document.title = fileName;
      } else {
        console.error("Opening the file was blocked by the browser.");
        window.location.href = url;
      }
    },
    displayFile(mimeType, file, fileName) {
      let byteCharacters = atob(file);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let blob = new Blob([byteArray], { type: mimeType });

      // Create a URL for the Blob
      let fileURL = URL.createObjectURL(blob);

      this.loading = false;

      // Open the file in a new tab
      let newTab = window.open(fileURL, "_blank");
      if (newTab) {
        newTab.document.title = fileName;
      } else {
        // Opening the new tab was blocked by the browser
        console.error("Opening the file was blocked by the browser.");
        window.location.href = fileURL;
      }
    },
    displayImage(mimeType, file, fileName) {
      let image = new Image();
      image.src = `data:image/${mimeType};base64,${file}`;
      let imgElement = document.createElement("img");
      imgElement.setAttribute(
        "style",
        "max-width: 100%; max-height: auto; height: fit-content; width: auto;"
      );
      imgElement.src = image.src;
      let imgContainer = document.getElementById("displayer");
      imgContainer.appendChild(imgElement);
      this.loading = false;
      new Modal(this.$refs.kt_modal_display).show();
    },
    displayVideo(mimeType, file, fileName) {
      let videoElement = document.createElement("video");
      videoElement.setAttribute("controls", "true");
      //videoElement.setAttribute("autoplay", "true");
      videoElement.setAttribute(
        "style",
        "max-width: 100%; max-height: auto; height: fit-content; width: auto;"
      );
      videoElement.src = "data:video/mp4;base64," + file;
      let videoContainer = document.getElementById("displayer");
      //videoContainer.innerHTML = "";
      videoContainer.appendChild(videoElement);
      this.loading = false;
      new Modal(this.$refs.kt_modal_display).show();
    },
    displayAudio(mimeType, file, fileName) {
      let audioElement = document.createElement("audio");
      audioElement.setAttribute("controls", "true");
      //videoElement.setAttribute("autoplay", "true");
      audioElement.setAttribute(
        "style",
        "max-width: 100%; max-height: auto; margin-left: 40px; margin-right:40px"
      );
      audioElement.src = "data:audio/mp3;base64," + file;
      let audioContainer = document.getElementById("displayer");
      audioContainer.appendChild(audioElement);
      this.loading = false;
      new Modal(this.$refs.kt_modal_display).show();
    },
    download(mimeType, file, fileName) {
      let fileB64 = "data:" + mimeType + ";base64," + file;
      let link = document.createElement("a");
      link.href = fileB64;

      link.download = fileName;
      this.loading = false;
      link.click();
    },
    selectedStyles(folderName) {
      if (this.isExpanded(folderName)) {
        return "border-bottom: 2px solid #FFF200;";
      }
    },
    columnFolders() {
      if (this.showFolders) {
        return "col-6 col-md-3 mt-3 folders";
      } else {
        return "col-1 mt-3 folders";
      }
    },
    columnsTableDocuments() {
      if (this.showFolders) {
        return "col-6 col-md-9 tableDocuments";
      } else {
        return "col-11 tableDocuments";
      }
    },
    foldersWidth() {
      if (!this.showFolders) {
        return "max-width: 70px; overflow: hidden;";
      }
    },
    eventModal() {
      this.$refs.kt_modal_display.addEventListener(
        "hidden.bs.modal",
        function (e) {
          const parent = e.target.querySelector(".modal-content");
          const children = [...parent.children];
          children.forEach((child) => {
            if (!child.classList.contains("modal-close")) {
              if (child.tagName === "VIDEO" || child.tagName === "AUDIO") {
                child.pause();
              }
              parent.removeChild(child);
            }
          });
        }
      );
    },
  },
  mounted() {
    setCurrentPageBreadcrumbs("Manuais");
    this.eventModal();
    this.loadTechManuals();
  },
};
</script>
<style>
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  display: flex;
  /*align-items: center;*/
  padding: 8px;
}

span {
  cursor: pointer;
}

i {
  margin-left: 8px;
}

.folders {
  max-height: 70vh !important;
  overflow-y: scroll;
  border-right: 1px solid #e5e5e5;
}

.modal {
  --bs-modal-width: fit-content;
  /* overflow-y: hidden;
  height: fit-content;*/
  max-width: 100%;
  max-height: 100%; 
}

.modal-content {
  /* width: auto; */
  position: relative; /* Set the modal content as a positioning context */
}

.modal-close {
  position: absolute; /* Position the header absolutely */
  top: 0;
  right: 0;
  z-index: 1; /* Ensure the close button appears above the content */
}
</style>
