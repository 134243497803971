<template>
  <!-- TABLE WITH 3 COLUMNS [Nome, Tamano, Descarregar] -->
  <div class="card card-custom">
    <div class="card-header ps-0">
      <div class="card-title">
        <h3 class="card-label">Documentos</h3>
      </div>
    </div>
    <div class="card-body ps-0">
      <table
        v-if="documents.length"
        class="table table-separate table-head-custom table-checkable"
        id="kt_datatable"
        style="position: relative"
      >
        <thead id="table-head">
          <tr class="">
            <th class="ps-2 fw-bolder fs-5">Nome</th>
            <th class="fw-bolder fs-5 text-center">Abrir</th>
            <!-- <th class="fw-bolder fs-5 text-center">Descarregar</th> -->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="document in documents"
            :key="document"
            style="vertical-align: middle"
          >
            <td
              class="ps-2 fw-bolder"
              style="word-break: break-all; overflow-x: hidden"
            >
              <i :class="icon(document)" class="me-2"></i>
              {{ document }}
            </td>
            <td class="text-center">
              <!--               <span
                v-if="loading"
                class="col-auto mt-sm-3 mb-sm-3"
              >
                <i class="fas fa-spinner fa-spin"></i>
              </span> -->
              <a
                @click="downloadPdf(document, false)"
                class="btn btn-sm btn-clean btn-icon"
                title="Abrir"
              >
                <i class="fas fa-external-link-alt"></i>
              </a>
            </td>
            <!-- <td class="text-center">
              <a @click="downloadPdf(document, true)" class="btn btn-sm btn-clean btn-icon" title="Descarregar">
                <i class="fas fa-download"></i>
              </a>
            </td> -->
          </tr>
        </tbody>

        <transition name="loader">
          <div v-if="loading" class="overlay-loader">
            <div class="text-center">
              <span
                class="spinner-border spinner-border-lg custom-loader align-middle my-2 text-primary"
                role="status"
              ></span
              ><br /><span class="text-white fs-4 fw-bold visually-hidden"
                >A carregar...</span
              >
            </div>
          </div>
        </transition>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomTableDocuments",
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
  },
  emits: ["downloadPdf"],
  methods: {
    icon(document) {
      if (!document || !document.length) return "fas fa-folder";
      const extension = document.split(".")[1];
      switch (extension) {
        case "txt":
          return "fas fa-file-alt";
        case "pdf":
          return "fas fa-file-pdf";
        case "doc":
        case "docx":
          return "fas fa-file-word";
        case "xls":
        case "xlsx":
          return "fas fa-file-excel";
        case "ppt":
        case "pptx":
          return "fas fa-file-powerpoint";
        case "zip":
        case "rar":
          return "fas fa-file-archive";
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
          return "fas fa-file-image";
        case "mp3":
        case "wav":
          return "fas fa-file-audio";
        case "mp4":
        case "avi":
        case "mov":
          return "fas fa-file-video";
        default:
          return "fas fa-file";
      }
    },
    downloadPdf(pdf, isDownload) {
      this.$emit("downloadPdf", pdf, isDownload);
    },
  },

  mounted() {
    console.log(this.documents);
  },
};
</script>

<style scoped>
.card-custom {
  max-height: 70vh;
  overflow-y: scroll;
}

#table-head {
  background-color: #fff200;
}
tbody tr:nth-child(even) {
  background-color: #eef9ff;
}

tbody tr:nth-child(odd) {
  background-color: #ddf3ff;
}
</style>
